.carousel-item {
    width: 100%;
    height: 350px;
}

.carousel-item img {
    opacity: 1;
}

.carousel-caption {
    /* background-color: rgba(0, 0, 0, 0.3); */
    padding: 10px;
    color: #fff;
}

.carousel-indicators {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.carousel-indicators button {
    background-color: #2A2A46 !important;
}

.carousel-item-preview {
    width: 120px;
    height: 120px;
    object-fit: cover;
    object-position: center center;
}

