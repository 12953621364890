.modal-container {
  padding-bottom: 130px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  & button {
    box-shadow: none !important;
  }

  &.--fullScreen {
    & .modal-dialog {
      height: inherit !important;
      position: absolute !important;
      right: 0 !important;
      top: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      min-width: calc(100vw - 335px) !important;

      & .modal-content {
        min-width: calc(100vw - 300px) !important;
        min-height: 100vh !important;
        position: absolute !important;
        right: 0 !important;
      top: 0 !important;
      }

      & .modal-body {
        overflow: auto !important;
        text-align: center !important;
        width: inherit;
        height: inherit;
      }

      & .ReactCrop {
        // & img {
        // min-width: calc(100vw - 300px) !important;
        // width: calc(100vw - 300px) !important;
        // min-height: auto !important;
        // width: 100% !important;
        // height: auto !important;
        // position: absolute !important;
        // right: 0 !important;
        // bottom: 0 !important;
        // }
      }

      & .confirm-crop {
        position: fixed !important;
        top: 33px !important;
        right: 70px !important;
        z-index: 99 !important;
        cursor: pointer !important;
        color: greenyellow !important;
      }
    }
  }

  & .modal-dialog {
    width: inherit;
    height: inherit;
    padding: 0 !important;
    margin: 0 !important;

    & .modal-content {
      width: auto;
      height: auto;
      max-height: 90%;
      max-width: 1980px !important;
      padding: 15px 10px;

      & .modal-body {
        overflow: auto;
        text-align: center;
      }

      & .confirm-crop {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 99 !important;
        cursor: pointer;
        color: greenyellow;
      }

      & .modal-footer {
        border: none;

        & div:nth-child(1) > button {
          background-color: #ffffff;
          color: #2a2a46;
          border: solid 2px #2a2a46;
        }
        & div:nth-child(2) > button {
          background-color: #2a2a46;
          color: #ffffff;
          border: solid 2px #2a2a46;
        }
      }
    }
  }

  & embed {
    width: 100% !important;
    height: 100% !important;
  }
}

.--btn-close-top-left {
  position: absolute;
  top: 10px;
  right: 10px;
}

.--variant-dark {
  background-color: #2a2a46 !important;
  color: #ffffff !important;
  border: solid 2px #2a2a46 !important;
}
.--variant-dark:hover {
  background-color: #ffffff !important;
  color: #2a2a46 !important;
  border: solid 2px #2a2a46 !important;
}

.--variant-light {
  background-color: #ffffff !important;
  color: #2a2a46 !important;
  border: solid 2px #2a2a46 !important;
}

.--variant-light:hover {
  background-color: #2a2a46 !important;
  color: #ffffff !important;
  border: solid 2px #2a2a46 !important;
}
