#loaderBG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1233;
  background: rgba(235, 229, 217, 0.5);

  & .loader {
    display: flex;
    margin: 0 auto;
  }
}
