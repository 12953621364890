@font-face {
  font-family: "Inter-Black";
  src: local("Inter-Black"),
    url("./fonts/Inter/Inter-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"),
    url("./fonts/Inter/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-ExtraBold";
  src: local("Inter-ExtraBold"),
    url("./fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-ExtraLight";
  src: local("Inter-ExtraLight"),
    url("./fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Light";
  src: local("Inter-Light"),
    url("./fonts/Inter/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"),
    url("./fonts/Inter/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url("./fonts/Inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter-SemiBold"),
    url("./fonts/Inter/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Thin";
  src: local("Inter-Thin"),
    url("./fonts/Inter/Inter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Stretch-Pro";
  src: local("Stretch-Pro"),
    url("./fonts/stretch-pro/StretchPro.otf") format("truetype");
}
