.text-switch-creative {
  color: var(--main-raisin-black-black-1, #080707);
  text-align: center;
  leading-trim: both;
  text-edge: cap;

  /* Paragraph/Bold/$paragraph-bold-16.38 */
  font-family: Trap;
  font-size: 16.38px;
  font-style: normal;
  font-weight: 700;
  line-height: 21.835px; /* 133.3% */
  letter-spacing: -0.737px;
}
