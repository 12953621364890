#profile-image-label {
  position: relative;
  z-index: 2;
  width: auto;
  height: 250px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

#profile-image-label img {
  width: 31px;
  height: 25px;
}

#header-image-label {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 400px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

#header-image-label img {
  width: 31px;
  height: 25px;
}

.btn-headerImage-resize {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(25, 135, 84, 0.3);
  border: none;
  box-shadow: none;
  outline: none;
}

.btn-headerImage-resize:hover,
.btn-headerImage-resize:active,
.btn-headerImage-resize:focus {
  background: rgba(25, 135, 84, 1);
}
